@import '../../style/App.scss';

.blog {
  background-color:#dfe6e5; //$light-color;
  margin-top: 30px;
  //padding: 60px 0;
}
/* swiper-button-next and swiper-button-prev already have default styles from Swiper.css */
/* Custom CSS for Swiper navigation buttons */
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -6px;
  left: 0;
  width: 100%;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 2px 6px;
}
/* Ensure swiper icons are loaded and applied correctly */
.swiper-button-next,
.swiper-button-prev {
  font-family: swiper-icons !important;
  font-size: 20px; /* Adjust size as needed */
}

/* Ensure pagination bullets are styled correctly */
.swiper-pagination-bullet::before {
  font-family: swiper-icons !important;
  font-size: 12px; /* Adjust size as needed */
}



.swiper-pagination-bullet-active {
  opacity: 1;
  background: #67b41e;
}
.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
}


.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 40px; /* Change the size as needed */
  color:  #67b41e;
}


@media screen and (max-width: 990px) {
  #blog {
    text-align: center;
  }
}