@import '../../style/App.scss';

.services {  
  width: 100%;
 // padding-bottom: 60px;
  .ROW{
    padding-top: 60px;
  }
  .hero-info {
    padding-top: 30px;
  }
  .team__box {
    max-width: 350px;
    height: 290px;
    margin: 0 auto;
    margin-top: 15px;
    position: relative;
    border: 4px solid $main-color;
    img {
      width: 100%;
      height: 100%;
    }
    h4{
      display: none;
    }
  
    .team__box-info {
      background-color: $main-color;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: left;
      width: 100%;
      height: 80px;
      padding: 5px 20px;
  
      p{
        color: $black-color;
        overflow-wrap: break-word;
      }
      .weight800 {
        color: $black-color;       
        word-wrap: break-word;
      }
    }
  }
  .team__box:hover{
    img {
      cursor: pointer;
      transition: 0.3s ease;
      background-color: rgba(103, 180, 30, 0.8);
      //opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; 
   
      .h4{
        display: block;
       
      }
    }
  }
  /*.feature-button{
    max-width: 370px;
    height: 250px;
    margin: 0 auto;   
    position: relative;
    border: 4px solid $main-color;
    padding: 80px;
    margin-bottom: 60px;
    cursor: pointer;
    transition: 0.3s ease;
    h4{
      display: none;
    }
  }
    .feature-button:hover{
      cursor: pointer;
    transition: 0.3s ease;
    background-color: rgba(103, 180, 30, 0.6);  
    width: 100%;
    
   
      .h4{
        display: block;
       
      }
    }  
  
  .hero-image {
    width: 456px;
    height: 720px;    
    background-position: 50% 50%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 50% !important;
      
    }
  }*/
}
/* ModalStyles.css */
.modalOverlay {
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99;


.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  padding: 20px;
  border-radius: 10px;
}
}
@media (max-width: 768px) {
  .modalContent {
    width: 95%;
    padding: 15px;
  }
}


@media screen and (max-width: 1080px) {
  .mission {
   // padding-top: 140px;

    .hero-info {
      padding-top: 0px;
    }

    .hero-image {
      float: none;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 990px) {
  .mission {
    .hero-info {
      text-align: center;
    }
  }
}