@import '../../style/App.scss';

.team__info {
  max-width: 370px;
  height: 100%;
  margin: 0 auto;
  //margin-top: 60px;
  position: relative;
  border: 4px solid $main-color;
  padding:20px;
  margin-bottom: 60px;
  padding-bottom:50px;
  h4 {
    padding-bottom: 20px;
  }
  p {
    padding-bottom: 20px;
  }
  p:last-child {
    cursor: pointer;
    transition: 0.3s ease;
  }
  p:last-child:hover {
    color: $main-color;
  }
}
@media screen and (max-width: 990px) {
  .team__info  {
    h4  {
      padding-bottom: 2px;
    }
  }
}