@import '../../../style/App.scss';



.contact__social {
  width: 100%;
  margin: 15px 0 15px 0;
  transition: 0.1s ease;
  cursor: pointer;
  a {
    color:$main-color;
  }
}

.contact__social:hover {
 // filter: invert(77%) sepia(99%) saturate(652%) hue-rotate(354deg) brightness(98%) contrast(111%);
}



@media screen and (max-width: 990px) {


  .contact__social {
    text-align: center;
    width: 100%;
    img {
      margin: 0 auto;
    }
  }
}