@import '../../../style/App.scss';

.readMoreReadless {
  .content {
    transition: max-height 0.3s ease;
  }

  .show {
    max-height: none;
  }

  .hide {
    max-height: 150px; // Or any default limit you prefer
  }

  .Readbutton {
    margin-top: 10px;
    cursor: pointer;
  }
}
.Readbutton {
      background-color: $main-color;
    border: 2px solid $main-color;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 800;
    margin-left: 20px;
  
  .Readbutton:hover {
    border: 2px solid $main-color;
    background-color: transparent;
  }
}
.big__title {
 
  color: #3c8199;
}
@media screen and (max-width: 990px) {
  .big__title {
    width: 100%;
    text-align: center;
    color: #3c8199;
  }
}


@media screen and (max-width: 400px) {
  .big__title {
    h2 {
      font-size: 4rem;
    }
  }
}